<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-space-between class="mb-6">
      <v-flex xs6 sm4 md4 lg4>
        <h2 class="headline text-capitalize">{{ $t("new shift") }}</h2>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs6 sm4 md4 lg4 class="text-right">
        <v-btn
          small
          dark
          :loading="loading"
          :color="$store.state.secondaryColor"
          @click="uploadData"
        >
          <v-icon small left>save</v-icon>
          <span class="caption text-capitalize">{{ $t("register") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-alert>
      <v-form ref="form">
        <v-layout row wrap class="caption mt-1">
          <v-flex xs12 sm6 md3 lg3 class="px-1">
            <span class="font-weight-medium text-capitalize"
              >{{ $t("select business") }}
            </span>
            <v-text-field
              v-if="selectedBusiness"
              outlined
              dense
              v-model="selectedBusinessName"
              readonly
              :rules="rules.emptyField"
            ></v-text-field>

            <v-select
              v-else
              outlined
              dense
              item-text="businessName"
              item-value="id"
              :items="ownersBusinesses"
              v-model="shift.businessId"
              :rules="rules.emptyField"
            ></v-select>
          </v-flex>

          <v-flex xs12 sm6 md3 lg3 class="px-1">
            <span class="font-weight-medium text-capitalize">{{
              $t("shift name")
            }}</span>
            <v-text-field
              outlined
              dense
              v-model="shift.shiftName"
              :rules="rules.emptyField"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 class="px-1">
            <span class="font-weight-medium text-capitalize">{{
              $t("shift start time")
            }}</span>
            <v-dialog
              ref="dialog"
              v-model="startTimeModal"
              :return-value.sync="shift.startTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="shift.startTime"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  :rules="rules.emptyField"
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="startTimeModal"
                v-model="shift.startTime"
                scrollable
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startTimeModal = false">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(shift.startTime)"
                >
                  {{ $t("ok") }}
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 class="px-1">
            <span class="font-weight-medium text-capitalize">{{
              $t("shift end time")
            }}</span>
            <v-dialog
              ref="dialogs"
              v-model="endTimeModal"
              :return-value.sync="shift.endTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="shift.endTime"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  :rules="rules.emptyField"
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="endTimeModal"
                v-model="shift.endTime"
                scrollable
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="endTimeModal = false">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogs.save(shift.endTime)"
                >
                  {{ $t("ok") }}
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-form>
    </v-alert>

    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("shift added successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not add a shift. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";

export default {
  data: () => ({
    snackSuccess: false,
    snackError: false,
    loading: false,
    timeout: 2000,
    startTimeModal: false,
    endTimeModal: false,

    shift: {
      businessId: "",
      shiftName: "",
      startTime: null,
      endTime: null,
    },
  }),

  computed: {
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    selectedBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    selectedBusinessName() {
      let busId = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      let busName = busId.businessName;
      return busName;
    },

    countries() {
      return this.countryList.map((item) => item.country);
    },
    rules() {
      return {
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || this.$t("please enter a valid email"),
        ],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },
  methods: {
    goBack() {
      window.history.back();
    },

    uploadData() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        db.collection("shifts")
          .add(this.shift)
          .then(() => {
            this.loading = false;
            this.snackSuccess = true;
            setTimeout(() => this.goBack(), 2000);
          })
          .catch(() => {
            this.snackError = true;
          });
      }
    },
  },
};
</script>

<style></style>
